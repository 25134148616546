import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SEO from "../components/seo"

import {
  StyledContainer,
  Product,
  ProductContent,
} from "../components/products/styles"

SwiperCore.use([Navigation, Pagination])

const Gaveteiros = () => {
  const data = useStaticQuery(graphql`
    query {
      gaveteirovolante: file(
        relativePath: { eq: "products/gaveteiro-volante.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gaveteirovolante2: file(
        relativePath: { eq: "products/gaveteiro-volante2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Gaveteiros" />

      <StyledContainer as="section">
        <Product>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ type: "fraction" }}
            // navigation
          >
            <SwiperSlide>
              <Img fluid={data.gaveteirovolante.childImageSharp.fluid} />
            </SwiperSlide>
            <SwiperSlide>
              <Img fluid={data.gaveteirovolante2.childImageSharp.fluid} />
            </SwiperSlide>
          </Swiper>

          <ProductContent>
            <h3>Gaveteiro Volante</h3>

            <ul>
              <li>Altura: 60 cm </li>
              <li>Largura: 45 cm</li>
              <li>Profundidade: 40 cm</li>
              <li>Pés rodízio</li>
            </ul>
          </ProductContent>
        </Product>
      </StyledContainer>
    </>
  )
}

export default Gaveteiros
